<template>
  <van-nav-bar
    title="入驻微信支付"
  />
  <van-form style="margin-top: 15px;" @submit="appleyForm">
    <van-cell-group >
      <van-field
        v-model="organizationTypeText"
        readonly
        isLink
        clickable
        input-align="right"
        label="主体类型"
        placeholder="点击选择"
        @click="organizationTypeShow = true"
      />
      <van-popup v-model:show="organizationTypeShow" position="bottom">
        <van-picker
          :columns="organizationTypeList"
          value-key="label"
          @confirm="pickConfirm($event, 'organizationType', 'organizationTypeText')"
          @cancel="organizationTypeShow = false"
        />
      </van-popup>
    </van-cell-group>
    <van-cell-group title="营业执照信息"  v-if="form.organizationType === '2' || form.organizationType === '4'">
      <van-field label-width="8em" input-align="right" label="营业执照扫描件">
        <template #input>
          <van-uploader
            v-model="form.businessLicenseInfo.businessLicenseCopy"
            :max-count="1"
            :max-size="10 * 1024 * 1024"
            :after-read="(file) => afterRead(file, 'businessLicenseCopyFile')"
          />
        </template>
      </van-field>
      <van-field
        v-model="form.businessLicenseInfo.businessLicenseNumber"
        label-width="8em"
        clickable
        input-align="right"
        label="营业执照注册号"
        placeholder="营业执照注册号/统一社会信用代码"
        maxlength="18"
      />
      <van-field
        v-model="form.businessLicenseInfo.merchantName"
        label-width="8em"
        clickable
        input-align="right"
        label="商户名称"
        placeholder="请输入商户名称"
      />
      <van-field
        v-model="form.businessLicenseInfo.merchantName"
        label-width="8em"
        clickable
        input-align="right"
        label="经营者姓名"
        placeholder="请输入经营者姓名"
      />
    </van-cell-group>
    <van-cell-group title="身份证信息" v-if="form.organizationType">
      <van-field label-width="8em" input-align="right" label="身份证人像面">
        <template #input>
          <van-uploader
            v-model="form.idCardInfo.idCardCopy"
            :max-count="1"
            :max-size="10 * 1024 * 1024"
            :after-read="(file) => afterRead(file, 'idCardCopyFile')"
          />
        </template>
      </van-field>
      <van-field label-width="8em" input-align="right" label="身份证国徽面">
        <template #input>
          <van-uploader
            v-model="form.idCardInfo.idCardNational"
            :max-count="1"
            :max-size="10 * 1024 * 1024"
            :after-read="(file) => afterRead(file, 'idCardNationalFile')"
          />
        </template>
      </van-field>
      <van-field
        v-model="form.idCardInfo.idCardName"
        label-width="8em"
        input-align="right"
        label="身份证姓名"
        placeholder="请输入身份证姓名"
      />
      <van-field
        v-model="form.idCardInfo.idCardNumber"
        label-width="8em"
        input-align="right"
        label="身份证号码"
        placeholder="请输入身份证号码"
      />
      <van-field
        v-model="form.idCardInfo.idCardValidTime"
        label-width="8em"
        input-align="right"
        label="身份证结束时间"
        placeholder="请输入 如: 2030-01-01或长期"
      />
    </van-cell-group>
    <van-cell-group title="结算账户信息" v-if="form.organizationType">
      <van-field label-width="8em" input-align="right" label="填写结算账户">
        <template #input>
          <van-switch v-model="form.needAccountInfo" size="24px"/>
        </template>
      </van-field>
      <template v-if="form.needAccountInfo">
        <van-field
          v-model="bankAccountTypeText"
          readonly
          isLink
          clickable
          input-align="right"
          label="账户类型"
          placeholder="点击选择"
          @click="(bankDisabled ? bankAccountTypeShow = true : bankAccountTypeShow = false)"
        />
        <van-popup v-model:show="bankAccountTypeShow" position="bottom">
          <van-picker
            :columns="bankAccountTypeList"
            value-key="label"
            @confirm="pickConfirm($event, 'bankAccountType', 'bankAccountTypeText')"
            @cancel="bankAccountTypeShow = false"
          />
        </van-popup>
        <van-field
          v-model="form.accountInfo.accountBank"
          label-width="8em"
          input-align="right"
          label="开户银行"
          placeholder="请输入开户银行"
        />
        <van-field
          v-model="form.accountInfo.accountName"
          label-width="8em"
          input-align="right"
          label="开户名称"
          placeholder="请输入开户名称"
        />
        <van-field
          v-model="form.accountInfo.bankAddressCode"
          label-width="8em"
          input-align="right"
          label="开户银行省市"
          placeholder="点击选择"
        />
        <van-field
          v-model="form.accountInfo.accountNumber"
          label-width="8em"
          input-align="right"
          label="银行账号"
          placeholder="请输入银行账号"
        />
      </template>
    </van-cell-group>
    <van-cell-group title="超级管理员信息" v-if="form.organizationType">
      <van-field
        v-model="form.contactInfo.contactName"
        label-width="8em"
        input-align="right"
        label="超级管理员姓名"
        placeholder="请输入超级管理员姓名"
      />
      <van-field
        v-model="form.contactInfo.contactIdCardNumber"
        label-width="8em"
        input-align="right"
        label="身份证件号码"
        placeholder="请输入超级管理员身份证件号码"
      />
      <van-field
        v-model="form.contactInfo.mobilePhone"
        label-width="8em"
        input-align="right"
        label="超级管理员身手机"
        placeholder="请输入超级管理员身手机"
      />
      <van-field
        v-model="form.contactInfo.contactEmail"
        label-width="8em"
        input-align="right"
        label="超级管理员邮箱"
        placeholder="请输入超级管理员邮箱"
      />
    </van-cell-group>
    <van-cell-group title="店铺信息" v-if="form.organizationType">
      <van-field
        v-model="form.salesSceneInfo.storeName"
        label-width="5em"
        input-align="right"
        label="店铺名称"
        placeholder="请输入店铺名称"
      />
      <van-field
        v-model="form.salesSceneInfo.storeUrl"
        disabled
        label-width="5em"
        input-align="right"
        label="店铺链接"
        placeholder="请输入店铺链接"
      />
      <van-field
        v-model="form.merchantShortname"
        label-width="5em"
        input-align="right"
        label="商户简称"
        placeholder="请输入商户简称,收款时将显示该简称"
      />
    </van-cell-group>
    <div style="margin: 16px;" v-if="form.organizationType">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      form: {
        businessLicenseInfo: {},
        idCardInfo: {},
        accountInfo: {},
        needAccountInfo: false,
        contactInfo: {
          contactType: '65'
        },
        salesSceneInfo: {}
      },
      organizationTypeShow: false,
      organizationTypeList: [
        { label: '小微商户', value: '2401' },
        { label: '个人卖家', value: '2500' },
        { label: '个体工商户', value: '4' },
        { label: '企业', value: '2' }
        // { label: '事业单位', value: '3' },
        // { label: '政府机关', value: '2502' },
        // { label: '社会组织', value: '1708' }
      ],
      organizationTypeText: '',
      idDocTypeText: '',
      idCardValidTimeShow: false,
      bankAccountTypeShow: false,
      bankAccountTypeList: [
        { label: '对私账户', value: '75' },
        { label: '对公账户', value: '74' }
      ],
      bankAccountTypeText: '',
      bankDisabled: true,
      businessLicenseCopyFile: '',
      idCardCopyFile: '',
      idCardNationalFile: '',
      businessLicenseCopyUpload: false,
      idCardCopyUpload: false,
      idCardNationalUpload: false
    })
    const formatter = (type, val) => {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
    }
    const pickDate = (e) => {
      const year = new Date(e).getFullYear()
      const month = new Date(e).getMonth() + 1
      const day = new Date(e).getDate()
      state.form.idCardInfo.idCardValidTime = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      state.idCardValidTimeShow = false
    }
    const afterRead = (e, type) => {
      state[type] = e.file
      if (type === 'businessLicenseCopyFile') state.businessLicenseCopyUpload = false
      if (type === 'idCardCopyFile') state.idCardCopyUpload = false
      if (type === 'idCardNationalFile') state.idCardNationalUpload = false
    }
    const pickConfirm = (e, type, label) => {
      state.form.[type] = e.value
      state[type + 'Show'] = false
      if (label) state[label] = e.label
      if (type === 'organizationType' && (e.value === '2401' || e.value === '2500')) {
        state.bankAccountTypeText = '对私账户'
        state.form.accountInfo.bankAccountType = '75'
        state.bankDisabled = false
      } else {
        state.bankDisabled = true
      }
    }
    const imageUpload = (file) => {
      return new Promise((resolve, reject) => {
        post('/imageupload', file).then(res => {
          resolve(res)
        })
      })
    }
    const appleyForm = async () => {
      const data = {
        applymentsRequest: state.form,
        storeId: state.storeId
      }
      Toast.loading({ message: '资料上传中\n请耐心等待', duration: 0, forbidClick: true })
      if ((state.form.organizationType === '4' || state.form.organizationType === '2') && state.businessLicenseCopyFile && !state.businessLicenseCopyUpload) {
        const formData = new FormData()
        formData.append('imageFile', state.businessLicenseCopyFile)
        const res = await imageUpload(formData)
        if (res.code === 0) {
          state.form.businessLicenseInfo.businessLicenseCopy = res.data.mediaId
          state.businessLicenseCopyUpload = true
        } else {
          Toast.clear()
          return Toast('营业执照上传失败，请重试')
        }
      }
      if (state.idCardCopyFile && !state.idCardCopyUpload) {
        const formData = new FormData()
        formData.append('imageFile', state.idCardCopyFile)
        const res = await imageUpload(formData)
        if (res.code === 0) {
          state.form.idCardInfo.idCardCopy = res.data.mediaId
          state.idCardCopyUpload = true
        } else {
          Toast.clear()
          return Toast('身份证人像面上传失败，请重试')
        }
      }
      if (state.idCardNationalFile && !state.idCardNationalUpload) {
        const formData = new FormData()
        formData.append('imageFile', state.idCardNationalFile)
        const res = await imageUpload(formData)
        if (res.code === 0) {
          state.form.idCardInfo.idCardNational = res.data.mediaId
          state.idCardNationalUpload = true
        } else {
          Toast.clear()
          return Toast('身份证国徽面上传失败，请重试')
        }
      }
      post('/createapply', data).then(res => {
        Toast.clear()
        if (res.code === 0) {
          Toast('申请已提交，请等待审核')
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      post('/util.getAllUrls', {
      }).then(res => {
        state.form.salesSceneInfo.storeUrl = res.data.shop + '?storeId=' + state.storeId
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      imageUpload,
      appleyForm,
      pickConfirm,
      afterRead,
      pickDate,
      formatter
    }
  }
}
</script>

<style scoped>
  .uploadbox{
    width: 80px;
    height: 80px;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    vertical-align: middle;
  }
  .uploadbox .uploadInput{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
</style>
